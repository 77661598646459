import React from "react"
import Seo from "../components/seo.js"

const links = [
  { name: "Portfolio", sub: "nimmervoll.work", href: "https://nimmervoll.work" },
  {
    name: "LinkedIn",
    sub: "connect with me",
    href: "https://www.linkedin.com/in/lorenz-nimmervoll-a728a5193"
  },
  { name: "Github", sub: "open-source :)", href: "https://github.com/NimmLor" },
  { name: "Thingiverse", sub: "my 3D-printing portfolio", href: "https://www.thingiverse.com/Surrbradl08" }
]

const IndexPage = () => {
  return (
    <>
      <Seo />
      <section className="w-full min-h-screen">
        <div className="relative h-full min-h-screen flex flex-col justify-between">
          {/*  pb-32 sm:pb-48 md:pb-56 */}
          <div className="absolute top-0 left-0 w-full h-full origin-top-left transform">
            {/*  -skew-y-6 */}
            <img
              src="https://cdn.devdojo.com/images/july2021/mesh-bg.jpg"
              className="absolute inset-0 object-cover w-full h-full select-none pointer-events-none"
            />
          </div>
          <div className="w-full px-4 lg:px-10 mx-auto max-w-7xl">
            <div className="relative flex items-center justify-between h-2 mx-auto"></div>
            <div className="container relative max-w-3xl mx-auto mt-20 sm:mt-24 lg:mt-32 mb-8">
              <h1 className="text-3xl font-extrabold leading-tight tracking-tight text-white sm:text-4xl md:text-5xl">
                Lorenz Nimmervoll
              </h1>
              <p className="flex items-center mt-6 text-base font-medium text-white sm:text-lg">
                <svg className="w-auto h-5 mr-2 text-white fill-current" viewBox="0 0 20 20">
                  <path d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z"></path>
                </svg>
                <span>A cloud engineer from Austria</span>
              </p>
              {/* <p className="mt-6 text-base text-pink-100">
                            Are you ready to start crafting some elegant designs that helps your website shine? If so, you are in for
                            a treat. Our TailwindCSS and AlpineJS drag'n drop page builder will help you crush your competition by
                            showing professional and sweet looking designs to impress your users.
                          </p> */}
            </div>
            {links.map((link, i) => (
              <a
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                key={i}
                className="relative flex justify-center max-w-3xl mx-auto mt-5 select-none text-sm sm:base"
              >
                <div className="transform hover:-translate-y-0.5 hover:shadow-md transition-all duration-300 shadow-sm inline-block px-6 py-3 w-full overflow-hidden font-mono font-medium text-white bg-white rounded-lg  bg-opacity-20 text-">
                  {/* <div className="flex items-center w-full h-12 pl-4 bg-transparent opacity-80">
                              <div className="flex space-x-1.5">
                              <div className="w-3 h-3 bg-red-400 rounded-full"></div>
                              <div className="w-3 h-3 bg-yellow-400 rounded-full"></div>
                              <div className="w-3 h-3 bg-green-400 rounded-full"></div>
                              </div>
                              </div>
                              <div className="px-5 pt-1 pb-5">
                              <p>$ sudo craft_awesome_designs</p>
                              <p className="mt-4">Loading Awesomeness...</p>
                              <p>Ready to Deploy 🚀</p>
                              <p className="flex items-center mt-4">
                                $ Enter Your Name: <span className="h-4 w-2.5 bg-white inline-block ml-2"></span>
                                </p>
                            </div> */}
                  <div>
                    {link.name}
                    {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                    <span className="ml-2 text-gray-300 font-normal">// {link.sub}</span>
                  </div>
                </div>
              </a>
            ))}
          </div>
          <div className="relative mt-auto">
            <a
              href="https://nimmervoll.work/imprint"
              className="block my-auto px-4 py-4 text-center text-gray-200 hover:text-white text-sm"
            >
              Imprint &amp; Privacy
            </a>
          </div>
        </div>
      </section>
      {/* <footer className="">
        <a href="https://nimmervoll.work/imprint" className="text-center text-gray-200 hover:text-white text-sm">
          Imprint & Privacy
        </a>
      </footer> */}
    </>
  )
}

export default IndexPage
