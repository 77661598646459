/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, isHome }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
            # image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  // const defaultImage = site.siteMetadata?.image
  // const siteUrl = site.siteMetadata?.url

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang
        }}
        title={title}
        titleTemplate={isHome ? title : defaultTitle ? `%s | ${defaultTitle}` : null}
        meta={[
          {
            name: `description`,
            content: metaDescription
          },
          {
            property: `og:title`,
            content: title
          },
          {
            property: `og:description`,
            content: metaDescription
          },
          {
            property: `og:type`,
            content: `website`
          },
          // {
          //   property: `og:image`,
          //   content: `${siteUrl}${defaultImage}`,
          // },
          {
            name: `twitter:card`,
            content: `summary`
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata?.author || ``
          },
          {
            name: `twitter:title`,
            content: title
          },
          {
            name: `twitter:description`,
            content: metaDescription
          }
        ].concat(meta)}
      >
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Heebo:300,400,500,600,700,800,900" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Oswald:700" />
        <style>
          {`html {
        scroll-behavior: smooth;
      }`}
        </style>
      </Helmet>
    </>
  )
}

SEO.defaultProps = {
  lang: "de",
  meta: [],
  description: ``,
  image: null
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string
}

export default SEO
